import React from "react";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx } from "theme-ui";

interface Props {
  text: string | string[];
}

const RenderItem: React.SFC<Props> = (props) => {
  if (Array.isArray(props.text)) {
    return (
      <li
        sx={{
          margin: "36px 0",
          ":first-of-type": {
            marginTop: 0,
          },
          ":hover > h4, :hover > span": {
            transition: "none",
            color: "secondary",
            cursor: "default",
          },
        }}
      >
        <h4
          sx={{
            color: "background",
            textTransform: "uppercase",
            fontWeight: 500,
            fontSize: "20px",
            mb: [0],
          }}
        >
          {props.text[0]}
        </h4>
        <span
          sx={{
            color: "background",
          }}
        >
          {props.text[1]}
        </span>
      </li>
    );
  }

  return (
    <li
      sx={{
        margin: "36px 0",
        ":first-of-type": {
          marginTop: 0,
        },
        ":hover > h4": {
          transition: "none",
          color: "secondary",
          cursor: "default",
        },
      }}
    >
      <h4
        sx={{
          fontSize: "20px",
          color: "background",
          textTransform: "uppercase",
          fontWeight: 500,
        }}
      >
        {props.text}
      </h4>
    </li>
  );
};

export default RenderItem;
