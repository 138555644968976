import * as React from "react";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx } from "theme-ui";

import el1 from "src/images/work-stages/img00001.svg";
import el2 from "src/images/work-stages/img00002.svg";
import el3 from "src/images/work-stages/img00003.svg";
import el4 from "src/images/work-stages/img00004.svg";
import el5 from "src/images/work-stages/img00005.svg";

const svgs = [el1, el2, el3, el4, el5];

interface Props {
  i: number;
}

const styles = {
  position: "relative",
  zIndex: 200,
  color: "gray",
  transform: "rotate(-90deg)",
  width: "fit-content",
  fontSize: [5],
  fontStyle: "normal",
  ":hover": {
    color: "secondary",
    WebkitTextStrokeWidth: 1,
    WebkitTextStrokeColor: "#000",
  },
};

const ItemNumber: React.SFC<Props> = (props) => {
  return (
    <div
      sx={{
        position: "relative",
      }}
    >
      <i sx={styles}>{"0" + (props.i + 1)}</i>
      <div
        sx={{
          top: 0,
          zIndex: 10,
          position: "absolute",
          left: [3, 0],
        }}
      >
        <img src={svgs[props.i] || svgs[Math.round(Math.random() * 3)]} />
      </div>
    </div>
  );
};

export default ItemNumber;
