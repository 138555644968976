import React from "react";
import RenderItem from "./RenderItem";

interface Props {
  items: (string | string[])[];
}

const LightningList: React.SFC<Props> = (props) => {
  return (
    <ul
      style={{
        margin: 0,
      }}
    >
      {props.items.map((curr, i) => (
        <RenderItem text={curr} key={i} />
      ))}
    </ul>
  );
};

export default LightningList;
