import React from "react";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx } from "theme-ui";
import ItemNumber from "./ItemNumber";
import { useThemeUI } from "theme-ui";
import classes from "./WorkStages.module.css";

interface Props {
  items: string[];
}

const styles = {
  margin: ["24px auto", "0 36px"],
  width: "176px",
  textAlign: ["center", "unset"],
  ":first-of-type": {
    ml: ["auto", 0],
    mt: 0,
  },
  ":hover div": {
    color: "secondary",
  },
};

const WorkStages: React.SFC<Props> = (props) => {
  const context = useThemeUI();

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: ["column", "unset"],
        overflowX: "scroll",
        pl: [0, "5vw"],
        pb: [0, [5]],
      }}
      className={classes.wrapper}
    >
      {props.items.map((curr, i) => (
        <div
          key={i}
          sx={{
            ...styles,
            ...{
              ":hover i": {
                color: "secondary",
                WebkitTextStrokeWidth: 1,
                WebkitTextStrokeColor: context.theme.colors.primary,
              },
            },
          }}
        >
          <ItemNumber i={i} />
          <div
            sx={{
              color: "background",
              width: "inherit",
            }}
          >
            {curr}
          </div>
        </div>
      ))}
    </div>
  );
};

export default WorkStages;
