import * as React from "react";
import { PageProps } from "gatsby";

import Icon from "src/images/services/fullContent.svg";
import ServicePage from "src/routes/ServicesPages";
import Seo from "src/components/Seo";

const items = [
  "Разработка логотипов и фирменного стиля",
  "Брендинг",
  "Дизайн печатной продукции",
  "Съемки фото и видео ",
  "Отрисовка 3D объектов ",
];

const ContentPage: React.SFC<PageProps> = () => {
  return (
    <div>
      <Seo
        pageTitle="Контент"
        pageDescription="Делаем брендинг, логотипы, полиграфию, фото и видео съемки."
      />
      <ServicePage header="Контент" img={Icon} listItems={items} />
    </div>
  );
};

export default ContentPage;
