import * as React from "react";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx, Styled, useThemeUI } from "theme-ui";

import SectionHeader from "src/components/SectionHeader";
import LightningList from "src/components/LightningList";
import WorkStages from "src/components/WorkStages";
import Tape from "src/components/Tape";
import ServicePageHeader from "src/components/ServicePageHeader";
import MainHeader from "src/components/MainHeader";
import littleArrow from "src/images/littleArrow.svg";
import { Link } from "gatsby";

const width = ["100%", "90%", "90%", "90%", "50%"];

interface Props {
  listItems: (string | string[])[];
  workStages?: string[];
  header: string;
  img: string;
}

const ServicePage: React.SFC<Props> = (props) => {
  const context = useThemeUI();

  return (
    <div>
      <div>
        <MainHeader />
        <div>
          <ServicePageHeader text={props.header} img={props.img} />
        </div>
        <div
          sx={{
            backgroundColor: "primary",
            overflowX: "hidden",
          }}
        >
          <div>
            <div
              sx={{
                width: "90vw",
                display: "flex",
                flexDirection: ["column", "row"],
                margin: "auto",
                marginTop: [5],
                mb: [8],
              }}
            >
              <SectionHeader text="виды работ" />
              <div
                sx={{
                  ml: ["auto", "10vw"],
                  marginY: [[4], "unset"],
                }}
              >
                <LightningList items={props.listItems} />
              </div>
            </div>
            {props.workStages ? (
              <div
                sx={{
                  width: "100%",
                  mb: [2],
                }}
              >
                <SectionHeader
                  text="этапы работ"
                  styles={{
                    ml: "5vw",
                    // mb: [5],
                  }}
                />
                <WorkStages items={props.workStages} />
              </div>
            ) : null}
          </div>
          <Tape />
          <Link
            to="/#contacts"
            sx={{
              textDecoration: "none",
              display: "flex",
              flexDirection: ["column", "unset"],
              justifyContent: "space-evenly",
              width: ["100%", "70vw"],
              minWidth: ["unset", 767],
              marginX: "auto",
              marginTop: [5],
              marginBottom: [8],
              alignItems: "center",
            }}
          >
            <SectionHeader text="стоимость проекта" />
            <Styled.h2
              // @ts-ignore
              sx={{
                width: "min-content",
                fontSize: "64px",
                color: "transparent",
                margin: [24, 0],
                ...{
                  WebkitTextStrokeWidth: 1,
                  WebkitTextStrokeColor: context.theme.colors.background,
                },
              }}
            >
              Оставить заявку
            </Styled.h2>
            <Styled.img src={littleArrow} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ServicePage;
