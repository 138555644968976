import React from "react";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx, Styled } from "theme-ui";

import PointText from "../PointText";

interface Props {
  text: string;
  img: string;
}

const ServicePageHeader: React.SFC<Props> = (props) => {
  return (
    <div
      sx={{
        margin: "auto",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        ml: ["auto", "22vw"],
        mt: [[3], "auto"],
        flexDirection: ["column", "unset"],
      }}
    >
      <div
        sx={{
          alignSelf: "flex-end",
          mr: ["auto", [7]],
          margin: ["auto", "unset"],
        }}
      >
        <PointText text="что делаем" />
        <Styled.h1>{props.text}</Styled.h1>
      </div>
      <div
        sx={{
          height: 350,
          marginBottom: ["-23%", "-5%"],
          marginTop: ["-23%", "10vh"],
        }}
      >
        <Styled.img
          sx={{
            height: "100%",
            width: "auto",
          }}
          src={props.img}
        />
      </div>
    </div>
  );
};

export default ServicePageHeader;
